<template>
  <div>
      <h1 class="white--text">Search Lender</h1>
      <p class="white--text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam perspiciatis debitis veniam earum, nobis aliquid alias quod totam consectetur sed, culpa, ducimus ab. Nam officia reiciendis excepturi aperiam nisi natus.</p>
      <p class="white--text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus asperiores impedit aperiam enim pariatur totam, quae amet et architecto facere! Quaerat ratione voluptatem distinctio, eius placeat nemo obcaecati ea doloremque.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>